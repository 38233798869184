<template>
  	<div class="file-card my-1">
		
		<a :href="fileUrl" download>
			<img :src="excel_icon" alt="Excel Icon" class="file-icon">
			<span class="file-name">{{ filename }}</span>		
		</a>

		<!-- <b-button
			v-ripple.400="'rgba(40, 199, 111, 0.15)'"
			variant="flat-danger"
			class="btn-icon rounded-circle"
		>
			<feather-icon icon="XIcon" size="12" />
		</b-button> -->
	</div>
</template>

<script>
	import Ripple from 'vue-ripple-directive'

	export default {
		props: {
			filename: {
				type: String,
				default: 'Archivo.xlsx'
			},
			fileUrl: String
		},
		directives: {
			Ripple
		},
		data () {
			return {
				excel_icon: require('@/assets/images/icons/excel_icon.png')
			}
		}
	}
</script>

<style scoped>
	.file-card {
		display: flex;
		align-items: center;
		padding: 10px;
		border-radius: 8px;
		width: fit-content;
		box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
	}

	.file-icon {
		margin-right: 10px;
	}

	.file-name {
		flex-grow: 1;
		margin-right: 10px;
		font-size: 14px;
	}
</style>