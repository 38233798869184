import { render, staticRenderFns } from "./ButtonReview.vue?vue&type=template&id=1ce8661c&scoped=true&"
import script from "./ButtonReview.vue?vue&type=script&lang=js&"
export * from "./ButtonReview.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ce8661c",
  null
  
)

export default component.exports