import axios from '@axios';

export default {
	namespaced: true,
	state: {},
	getters: {},
	mutations: {},
	actions: {
		fetchCompetition(ctx, { id }) {
			return new Promise((resolve, reject) => {
				axios
					.get(`/competitions/${id}/review`)
					.then(response => resolve(response))
					.catch(error => reject(error));
			});
		},
	}
}