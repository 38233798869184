<template>
	<div>
		<b-button
			id="buttonStatusId"
			v-ripple.400="'rgba(113, 102, 240, 0.15)'"
			:variant="`flat-${statusReview ? 'success' : 'danger'}`"
			:disabled="disabled"
			@click="toggleStatus"
		>
			<feather-icon :icon="statusReview ? 'CheckIcon': 'XIcon'" />Revisado
		</b-button>
	</div>
</template>

<script>
import Ripple from "vue-ripple-directive"
export default {
	props: {
		statusReview: {
			type: Number,
			default: 0
		},
		disabled: {
			type: Boolean,
			default: false
		}
	},
	directives: {
		Ripple
	},
	methods: {
		toggleStatus() {
			this.statusReview = this.statusReview === 0 ? 1 : 0
			this.$emit("update-status", this.statusReview)
		}
	}
}
</script>

<style scoped>
</style>