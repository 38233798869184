<template>
	<section>
		<b-card-countdown
			v-if="competitionData"
			stage="evaluación de propuestas"
			:deadline="deadline"
			:start-date="startDate"
			:stage-closed="stageClosed"
			@time-finished="handleTimeFinished"
			@not-yet-started="handleNotYetStarted"
		/>

		<b-row>
			<b-col cols="12">
				<b-card>
					<b-tabs
						content-class="mt-2"
						v-if="competitionData"
						justified
					>
						<b-tab
							active
							title="Propuesta Económica"
						>
							<tab-proposal-economic
								:competition-id="competitionData.id"
								:economic-proposal-tender="competitionData.tender.economic_proposal"
								:economic-proposal="competitionData.economic_proposal_bidder"
								:is-disabled="isDisabled"
							/>
						</b-tab>

						<b-tab title="Propuesta de Seguridad, Calidad, Medio Ambiente y Anti Corrupción">
							<tab-proposal-sig
								:tender-id="competitionData.tender.id"
								:competition-id="competitionData.id"
								:proposals-data="competitionData.proposals"
								:is-disabled="isDisabled"
							/>
						</b-tab>

						<b-tab title="Propuesta Técnica">
							<tab-proposal-technical
								:tender-id="competitionData.tender.id"
								:competition-id="competitionData.id"
								:proposals-data="competitionData.proposals"
								:is-disabled="isDisabled"
							/>
						</b-tab>
					</b-tabs>
				</b-card>
			</b-col>

			<b-col cols="12">
				<b-button-loading
					text="FINALIZAR REVISIÓN"
					size="lg"
					:block="true"
					@process-action="sendInformation"
					:processing="processing"
					:disabled="isDisabled"
				/>
			</b-col>
		</b-row>
	</section>
</template>

<script>
import {
	ref,
	onMounted,
	onUnmounted,
	onBeforeMount
} from "@vue/composition-api"
import { Stages, TenderStates } from "@core/utils/data"
import BButtonLoading from "@core/components/b-button-loading/BButtonLoading.vue"
import BCardCountdown from "@core/components/b-card-countdown/BCardCountdown"
import TabProposalEconomic from "./tab-economic/TabEconomic.vue"
import TabProposalSig from "./tab-sig/TabSig.vue"
import TabProposalTechnical from "./tab-technical/TabTechnical.vue"
import proposalStoreModule from "./proposalStoreModule"
import useNotifications from "@notifications"
import router from "@/router"
import axios from "@axios"
import VueSweetalert2 from "vue-sweetalert2"
import Vue from "vue"
import store from "@/store"
import moment from "moment"

Vue.use(VueSweetalert2)

export default {
	components: {
		BCardCountdown,
		BButtonLoading,
		TabProposalEconomic,
		TabProposalSig,
		TabProposalTechnical
	},
	data() {
		return {
			moment
		}
	},
	setup(props) {
		const { swalNotification, swalNotificationCloser, swalNotificationTimer } =
			useNotifications()

		const { FINALIZADO, EN_REVISION } = TenderStates

		const scheduleData = ref(null)
		const state = ref(null)
		const tenderId = ref(router.currentRoute.params.id)
		const competitionId = ref(router.currentRoute.params.competition_id)
		const deadline = ref(null)
		const startDate = ref(null)
		const processing = ref(false)
		const stageClosed = ref(false)
		const isDisabled = ref(false)
		const timeLeft = ref({
			tenMinutes: false,
			fiveMinutes: false,
			threeMinute: false
		})

		const competitionData = ref(null)

		/*  */

		const handleTimeFinished = () => {
			stageClosed.value = true
			isDisabled.value = true
		}
		const handleNotYetStarted = (value) => {
			/* if (competitionData.value.tender?.tender_state_id !== EN_REVISION) {
				stageClosed.value = true
				isDisabled.value = true
			} else {
			} */
			isDisabled.value = value
			stageClosed.value = value
		}

		const PROPOSAL_APP_STORE_MODULE_NAME = "app-proposal"

		// REGISTER MODULE
		if (!store.hasModule(PROPOSAL_APP_STORE_MODULE_NAME))
			store.registerModule(PROPOSAL_APP_STORE_MODULE_NAME, proposalStoreModule)

		// UNREGISTER ON LEAVE
		onUnmounted(() => {
			if (store.hasModule(PROPOSAL_APP_STORE_MODULE_NAME))
				store.unregisterModule(PROPOSAL_APP_STORE_MODULE_NAME)
		})

		const refetchTenderData = () => {
			store
				.dispatch("app-proposal/fetchCompetition", {
					id: competitionId.value
				})
				.then((response) => {
					if (response.data === "error") {
						competitionData.value = undefined
					} else {
						competitionData.value = response.data.competition

						setData()
					}
				})
				.catch((error) => {
					if (error.response.status === 404) {
						competitionData.value = undefined
					}
				})
		}

		const setData = () => {
			if (competitionData.value.tender.tender_state_id !== EN_REVISION) {
				stageClosed.value = true
				isDisabled.value = true
			}

			const evaluation = competitionData.value.tender.schedules.find(
				(item) => parseInt(item.stage_id) === Stages.EVALUACION_PROPUESTA
			)
			deadline.value = evaluation.actual_end_date || ""
			startDate.value = evaluation.actual_start_date || ""

			const currentDate = moment()
			const inputDate = moment(deadline.value)

			if (currentDate.isAfter(inputDate)) {
				stageClosed.value = true
				isDisabled.value = true
			}
		}

		onBeforeMount(() => {
			refetchTenderData()
		})

		const sendInformation = () => {
			processing.value = true

			Vue.swal({
				title: "¿Está seguro(a) de terminar la evaluación?",

				html: "<small>¡Recuerda <strong>guardar tus cambios</strong> antes de enviar!</small>",
				icon: "warning",
				showCancelButton: true,
				confirmButtonText: "Sí, continuar!",
				cancelButtonText: "Cancelar",
				customClass: {
					confirmButton: "btn btn-primary",
					cancelButton: "btn btn-outline-danger ml-1"
				},
				showClass: {
					popup: "animate__animated animate__tada"
				},
				buttonsStyling: false
			}).then(async (result) => {
				if (result.value) {
					try {
						const response = await axios.put(
							`/competitions/${competitionId.value}/review`,
							{ tender_id: tenderId.value }
						)
						swalNotification(response.data.message)
						router.push({
							name: "tenders-review",
							params: { id: tenderId.value },
							query: { tab: "1" }
						})
					} catch (error) {
						const message =
							error.response.data.message || "Error al finalizar evaluación"
						swalNotification(message, false)
					} finally {
						processing.value = false
					}
				} else {
					processing.value = false
				}
			})
		}

		return {
			competitionData,
			processing,
			timeLeft,
			isDisabled,
			stageClosed,
			deadline,
			startDate,

			sendInformation,
			handleTimeFinished,
			handleNotYetStarted
		}
	}
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-sweetalert.scss";
</style>